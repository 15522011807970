import { useEffect, useState } from "react";

import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";

import TaskList from "./TaskList";
import Stack from "@mui/material/Stack/Stack";
import AccordionPanel from "./AccordionPanel";
import Grid from "@mui/material/Grid/Grid";
import { useQuery } from "react-query";
import { TaskListType, fetchTaskList } from "../api/taskRequest";
import { useDispatch, useSelector } from "react-redux";
import { formatDateTaskListHeader } from "../utils";
import { setSelectedStatus } from "../reducer/selectedList";

export default function CalendarTaskList(props: any) {
  const [expanded, setExpanded] = useState<string | false>('panel1');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tasks, setTasks] = useState<TaskListType>([]);
  
  const dateRange = useSelector((state: any) => state.dateRange);
  const eventStatus = useSelector((state: any) => state.event);
  const workFilter = useSelector((state: any) => state.workFilter);
  
  const dispatch = useDispatch();
  
  const { data } = useQuery<TaskListType, Error>({
    queryKey: ['TaskList', dateRange.start_date, dateRange.end_date, eventStatus.sequence],
    queryFn: () => fetchTaskList(dateRange.start_date, dateRange.end_date),
    initialData: tasks,
  });
  
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      dispatch(setSelectedStatus(panel));
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    dispatch(setSelectedStatus("panel1"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', overflow: 'hidden', }}>
      <Grid container sx={{ maxHeight: '100%', alignContent: 'flex-start' }}>
        <Stack direction={'column'} sx={{ p: 0, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <Box sx={{ px: 2, pt: 1, pb: 1, flexDirection: "row", gap: 1, display: "flex", height: '35px', width: '100%', msFlexDirection: 'flex-start' }} >
            <Typography variant="h3">{formatDateTaskListHeader(dateRange.start_date)}</Typography>
          </Box>
        </Stack>
        <Stack direction={'column'} sx={{maxHeight: '100%',  width: '100%' }} >
          <AccordionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')} title="할 일" count={data ? data?.filter(data => (data.taskStatus === "inprogress") && (workFilter.selectedWorkStatus === "all" ? true : data.taskType === workFilter.selectedWorkStatus)).length : 0} location="calendar" rollup={props.expanded}>
            <TaskList type='inprogress' taskList={data || []}/>
          </AccordionPanel>
          <AccordionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')} title="완료" count={data ? data?.filter(data => (data.taskStatus === "complete") && (workFilter.selectedWorkStatus === "all" ? true : data.taskType === workFilter.selectedWorkStatus)).length : 0} location="calendar" rollup={props.expanded}>
            <TaskList type='complete' taskList={data || []}/>
          </AccordionPanel>
        </Stack>
      </Grid>
    </Box>
  );
}
