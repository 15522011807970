import axios from 'axios';

const url = 'https://graph.microsoft.com';

const NewApi = axios.create({
	baseURL: url,
	// withCredentials: true,
});

// NewApi.defaults.withCredentials = true;

NewApi.interceptors.request.use(
	function (config) {
			const token = sessionStorage.getItem("accessToken");
			if (token) {
					config.headers['Authorization'] = `Bearer ${token}`;
			}
			config.headers['Content-Type'] = 'application/json';
			return config;
	},
	function (error) {
			return Promise.reject(error);
	}
);

NewApi.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export default NewApi;