import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DateRange {
  start_date: string | null;
  end_date: string | null;
  target_date?: string | null;
}

const initialState: DateRange = {
    start_date: null,
    end_date: null,
    target_date: null
};

const dateRangeSlice = createSlice({
  name: "dateRange",
  initialState,
  reducers: {
    setDateRange: (state, action: PayloadAction<DateRange>) => {
      state.start_date = action.payload.start_date;
      state.end_date = action.payload.end_date;
      state.target_date = action.payload.target_date;
    },
  },
});

export const { setDateRange } = dateRangeSlice.actions;
export default dateRangeSlice.reducer;