import { Padding } from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";

const theme = createTheme({
  palette: {
    primary: {
      main: "#007CFF",
    },
    secondary: {
      main: "#00C1C1",
    },
    error: {
      main: "#FF2E00",
    },
    warning: {
      main: "#D3D3D3",
    },
    info: {
      main: "#413F3F",
    },
    success: {
      main: "#007CFF",
    },
    text: {
      primary: "#242424",
      secondary: "#242424",
      disabled: "#BFBFBF",
    },
  },
  typography: {
    fontFamily: "Pretendard Variable",
    h1: {
      fontSize: "1.3rem",
      fontWeight: 700,
      color: "#242424",
    },
    h2: {
      fontSize: "1rem",
      fontWeight: 700,
      color: "#242424",
    },
    h3: {
      fontSize: "1rem",
      fontWeight: 700,
      color: "#242424",
    },
    h4: {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: "#007CFF",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 700,
      color: "#051532",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          fontWeight: 500,
          color: "#000",
          boxShadow: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#242424",
          fontWeight: 700,
          fontSize: "1rem",
          // minHeight: '30px',
          // height: '30px',
          "&.Mui-selected": {
            color: "#242424",
            borderBottom: "2px solid #007CFF",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          // minHeight: '30px',
          // height: '30px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "30px",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiListItemButton-root": {
            padding: "8px 16px 8px 0px",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#E7E7E7",
          color: "#E7E7E7",
          border: "1px solid #E7E7E7",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          textOverflow: "ellipsis",
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          "&.MuiListItemText-primary": {
            fontSize: "1rem",
            fontWeight: 500,
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          minWidth: "0px",
          px: "0px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          minWidth: "0px",
          "& .MuiButton-startIcon": {
            Margin: "0px",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPopover-paper": {
            borderRadius: "10px",
            minWidth: "200px",
          }
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: "100%",
          maxWidth: "100%",
          minHeight: "388px",
          maxHeight: "388px",
          padding: "0px",
          "& .MuiPickersSlideTransition-root": {
            minHeight: "300px",
            backgroundColor: "#F4F4F4",
          },
          "& .MuiPickersCalendarHeader-root": {
            margin: "0px",
            paddingTop: "12px",
            paddingBottom: "4px",
            backgroundColor: "#FFF",
          },
          "& .MuiPickersCalendarHeader-label": {
            fontWeight: 600,
            fontSize: "1.2rem",
            "& .MuiPickersArrowSwitcher-root": {
              "& MuiIconButton-root.MuiPickersArrowSwitcher-button:hover": {
                border: "1px solid #E8E8E8",
              },
            },
          },
          "& .MuiPickersDay-today": {
            border: "1px solid #007AFF",
            borderRadius: "13px",
          },
          "& .MuiPickersDay-today.Mui-selected": {
            backgroundColor: "#007AFF",
          },
          "& .MuiPickersDay-today.Mui-selected:hover": {
            backgroundColor: "#007AFF",
          },
          "& .MuiPickersDay-root": {
            width: "38px",
            height: "38px",
            borderRadius: "13px",
          },
          "& .MuiPickersDay-root:focus.Mui-selected": {
            backgroundColor: "#007AFF",
          },
          "& .MuiPickersDay-root.Mui-selected": {
            backgroundColor: "#007AFF",
          },
          "& .MuiPickersDay-root.Mui-selected:hover": {
            backgroundColor: "#007AFF",
          },
          "& .MuiDateCalendar-root": {
            width: "100%",
            height: "100%",
            maxHeight: "none",
          },
          "& .MuiDayCalendar-header": {
            display: "flex",
            justifyContent: "space-around",
            margin: "0px",
            fontSize: "0.9rem",
            padding: "3px 15px 3px 15px",
            backgroundColor: "#FFF",
            "& .MuiTypography-root": {
              fontSize: "0.9rem",
              fontWeight: "bold",
              width: "42px",
              height: "42px",
              margin: "0px",
              padding: "7px",
            },
          },
          "& .MuiDayCalendar-weekContainer": {
            justifyContent: "space-around",
            backgroundColor: "#F4F4F4",
            margin: "0px",
            padding: "5px 15px 5px 15px",
            borderTop: "2px solid #E8E8E8",
            "& .Mui-selected": {
              color: "rgba(255, 255, 255, 1) !important",
            },
            "& .MuiPickersDay-root": {
              fontSize: "1rem",
            },
            "& .MuiPickersDay-dayOutsideMonth": {
              color: "rgba(0, 0, 0, 0.25)",
            },
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root": {
            borderRadius: "10px",
            border: "none",
            width: '100%'
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "38px !important",
          "& .Mui-expanded": {
            margin: "0px 0px !important",
          }
        },
        content: {
          margin: "0px 0px !important",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginTop: "0px !important",
          marginBottom: "0px !important",
          maxWidth: '100%',
          maxHeight: '100%',
          "& .Mui-expanded": {
            marginTop: "0px !important",
            marginBottom: "0px !important",
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          maxWidth: '100%',
        },
      }
    },
  },
});

export default theme;
