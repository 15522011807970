import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedListState {
  status: string;
  taskId: string[];
}

const initialState: SelectedListState = {
  status: "panel1",
  taskId: []
};

const SelectedListSlice = createSlice({
  name: "SelectedList",
  initialState,
  reducers: {
    setSelectedStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setSeletedTasks: (state, action: PayloadAction<string[]>) => {
      state.taskId = action.payload;
    },
  },
});

export const { setSelectedStatus, setSeletedTasks } = SelectedListSlice.actions;
export default SelectedListSlice.reducer;