import { Fragment, useState } from 'react';

import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import List from "@mui/material/List/List";
import ListItemButton from '@mui/material/ListItemButton/ListItemButton';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton/IconButton';
import Divider from '@mui/material/Divider/Divider';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Dialog from '@mui/material/Dialog/Dialog';
import AppBar from '@mui/material/AppBar/AppBar';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import theme from '../theme';
import StorePanel from './StorePanel';
import { StoreInfoType, StoreListType } from '../api/taskRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setEventSequence } from '../reducer/eventStatus';

interface StoreListProps {
  storeList: StoreListType;
}
 
export default function StoreList({storeList}: StoreListProps) {
  const [open, setOpen] = useState(false);
  const [store, setStore] = useState<StoreInfoType>({bizplNm: "", bizplCd: "", taskStatus: ""});
  
  const handleOpen = (item: StoreInfoType) => { 
    setOpen(true); 
    setStore(item);
  };

  const dispatch = useDispatch();
  const eventStatus = useSelector((state: any) => state.event);
  const handleClose = () => { setOpen(false);  dispatch(setEventSequence(eventStatus.sequence + 1));};
  
  return (
    <Grid item xs={12} sx={{ height: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box sx={{ flex: "1 1 auto", overflow: "auto" }}>
          <List sx={{ p: 0 }}>
            {storeList.map((item, index) => (
              <Fragment key={item.bizplCd}>
                <ListItemButton
                  key={item.bizplCd}
                  sx={{ minHeight: "61px" }}
                  onClick={() => handleOpen({bizplNm: item.bizplNm, bizplCd: item.bizplCd, taskStatus: item.taskStatus})}
                >
                  <Box
                    component="img"
                    src={"./icons/store.png"}
                    sx={{
                      ml: 2,
                      width: 35,
                      height: 35,
                      filter: item.taskStatus === "complete" ? "none" : "grayscale(100%)",
                    }}
                  />
                  <ListItemText primary={item.bizplNm} sx={{ pl: 1 }} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <NavigateNextIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItemButton>
                {index < storeList.length - 1 && (
                  <Divider
                    variant="fullWidth"
                    component="li"
                    sx={{
                      flexGrow: 1,
                      borderColor: "#E0E0E0",
                      borderBottomWidth: "1px",
                      borderTopWidth: "0px",
                    }}
                  />
                )}
              </Fragment>
            ))}
          </List>
        </Box>
      </Box>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <ArrowBackIcon sx={{ strokeWidth: 1, stroke: theme.palette.info.main }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <StorePanel storeInfo={store}/>
      </Dialog>
    </Grid>
  );
}