import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WorkStatus {
  selectedWorkStatus: 'all' | 'team' | 'private';
}

const initialState: WorkStatus = {
  selectedWorkStatus: 'all',
};

const workFilterSlice = createSlice({
  name: "workFilter",
  initialState,
  reducers: {
    setWorkStatus: (state, action: PayloadAction<'all' | 'team' | 'private'>) => {
      state.selectedWorkStatus = action.payload;
    },
  },
});

export const { setWorkStatus } = workFilterSlice.actions;
export default workFilterSlice.reducer;