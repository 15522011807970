import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import Box from "@mui/material/Box/Box";
import Badge from "@mui/material/Badge/Badge";
import Divider from "@mui/material/Divider/Divider";
import { useSelector } from "react-redux";
import { AccordionPanelProps } from "../interface";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  width: '100%',
  maxHeight: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  flexDirection: "row",
  '& .MuiAccordionSummary-expandIconWrapper': {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  },
  '& .MuiAccordionSummary-expandIconWrapper .rotate-icon': {
    transition: 'transform 0.3s ease-in-out',
  },
  "&.Mui-expanded .MuiAccordionSummary-expandIconWrapper .rotate-icon": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded ": {
    transform: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  flexGrow: 1,
  overflowY: "scroll",
  padding: theme.spacing(0),
}));

export default function AccordionPanel({ expanded, onChange, title, children, count, rollup }: AccordionPanelProps) {
  const tabIndex = useSelector((state: any) => state.tab);
  
  return (
    <Accordion expanded={expanded} onChange={onChange} >
      <AccordionSummary
        aria-controls={`${title}-content`}
        id={`${title}-header`}
        sx={{ justifyContent: 'space-between !important' }}
        expandIcon={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ArrowForwardIosSharpIcon className="rotate-icon" sx={{stroke: "#242424", strokeWidth: 1 }}/>
            <Badge
              badgeContent={count}
              showZero={true}
              overlap="circular"
              color={count > 0 ? "info" : "warning"}
              sx={{ marginRight: '12px', '& .MuiBadge-badge': { borderRadius: '8px', height: '23px' }, }}
            />
          </Box>
        }
      >
        <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>{title}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, mx: 2 }}>
          <Divider
            orientation="horizontal"
            sx={{
              flexGrow: 1,
              borderColor: 'rgba(188, 188, 195, 0.44)',
              borderBottomWidth: '1px',
              borderTopWidth: '0px',
            }}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{maxHeight: (tabIndex.selectedIndex === 0 ? 'calc(100dvh - 271px)' : `calc(100dvh - ${rollup ? '238' : '644'}px)`), display: 'flex', overflow: 'auto' }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
