import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserInfoType {
  email: string | null;
  name: string | null;
  username: string | null;
  uid: string | null;
  role: string | null;
}

const initialState: UserInfoType = {
  email: null,
  name: null,
  username: null,
  uid: null,
  role: null,
};

const userInfoSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<{ email: string; name: string; username: string; uid: string; role: string | null}>) => {
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.role = action.payload.role;
    },
  },
});

export const { setUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;