import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SendStore {
  doSendStore: boolean;
}

const initialState: SendStore = {
  doSendStore: false,
};

const sendStoreSlice = createSlice({
  name: "SendStore",
  initialState,
  reducers: {
    setSendStore: (state, action: PayloadAction<boolean>) => {
      state.doSendStore = action.payload;
    },
  },
});

export const { setSendStore } = sendStoreSlice.actions;
export default sendStoreSlice.reducer;