import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import workFilterReducer from '../reducer/workFilter';
import tabStateReducer from '../reducer/tabState';
import funcBarStatusReducer from '../reducer/funcBarStatus';
import userInfoReducer from '../reducer/user';
import dateRangeReducer from '../reducer/dateRange';
import eventStatusReducer from '../reducer/eventStatus';
import selectedListReducer from '../reducer/selectedList';
import sendStoreReducer from '../reducer/sendStore';
export const store = configureStore({
	reducer: {
		workFilter: workFilterReducer,
		tab: tabStateReducer,
		funcBar: funcBarStatusReducer,
		user: userInfoReducer,
		dateRange: dateRangeReducer,
		event: eventStatusReducer,
		selectedList: selectedListReducer,
		sendStore: sendStoreReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;


export default store;