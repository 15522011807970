import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useState } from 'react';

export default function Alert({ isConfirm, alertContents, isOpen, alertCloseHandler }: { isConfirm: boolean, alertContents: string, isOpen: boolean, alertCloseHandler: () => void }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState<boolean>(isOpen);

  const handleClose = () => {
    alertCloseHandler();
  };

  const handleConfirm = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{zIndex: 10000}}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alertContents}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isConfirm && <Button onClick={handleConfirm}>취소</Button>}
        <Button onClick={handleClose} autoFocus>확인</Button>
      </DialogActions>
    </Dialog>
  );
}
