import { forwardRef, useEffect, useState } from "react";

import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack/Stack";


import { Typography } from "@mui/material";
import dayjs from "dayjs";
import 'dayjs/locale/ko';
import AccordionPanel from "./AccordionPanel";
import TaskList from "./TaskList";
import { useDispatch, useSelector } from "react-redux";
import { StoreInfoType, TaskListType, fetchStoreTaskList } from "../api/taskRequest";
import { useQuery } from "react-query";
import { setSelectedStatus } from "../reducer/selectedList";

dayjs.locale('ko');

const style = {
    display: "flex",
    flexDirection: "column",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: "100%",
    height: "100dvh",
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    pt: 9,
  };


// const StorePanel = forwardRef((storeInfo: StoreInfoType) => {
const StorePanel = forwardRef<HTMLDivElement, { storeInfo: StoreInfoType }>((props, ref) => {
  const { storeInfo } = props;
  
  const eventStatus = useSelector((state: any) => state.event);
  const dateRange = useSelector((state: any) => state.dateRange);
  
  const [expanded, setExpanded] = useState<string | false>('panel1');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tasks, setTasks] = useState<TaskListType>([]);
  
  const dispatch = useDispatch();
  
  
  const { data, refetch } = useQuery<TaskListType, Error>({
    queryKey: ['StoreTaskList', dateRange.start_date, dateRange.end_date, eventStatus.sequence, storeInfo.bizplNm],
    queryFn: () => fetchStoreTaskList(dateRange.start_date, dateRange.end_date, storeInfo.bizplCd),
    initialData: tasks,
  });

  
  const handleChange =
  (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    dispatch(setSelectedStatus(panel));
    setExpanded(newExpanded ? panel : false);
  };
  
  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    dispatch(setSelectedStatus("panel1"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={style} alignContent={"center"} alignItems={"center"} >
      <Box component="img" src={"./icons/store.png"} sx={{ width: 57, height: 57, mb: '9px' }} />
      <Typography sx={{fontSize: '21px', fontWeight: 800}}>{storeInfo.bizplNm}</Typography>
      <Stack direction={'column'} sx={{maxHeight: '100%',  width: '100%', pt: 3 }} >
        <AccordionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')} title="할 일" count={data ? data?.filter(item => item.bizplInfo.some(info => info.assignedTaskStatus === "inprogress")).length : 0} location="calendar">
          <TaskList type='inprogress' taskList={data || []} bizplCd={storeInfo.bizplCd}/>
        </AccordionPanel>
        <AccordionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')} title="완료" count={data ? data?.filter(item => item.bizplInfo.some(info => info.assignedTaskStatus === "complete")).length : 0} location="calendar">
          <TaskList type='complete' taskList={data || []} bizplCd={storeInfo.bizplCd}/>
        </AccordionPanel>
      </Stack>
    </Box>
  );
});

export default StorePanel;