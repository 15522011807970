import Api from './authApiConfig';

export const checkAuthApi = async (groupId) => {
	let res;
	try {
		res = await Api.get(`/v1.0/me/memberOf?$filter=id eq '${groupId}'`);
	} catch (error) {
	} finally {
		return res;
	}
};
