import { forwardRef, useEffect, useState } from "react";
import { Fragment } from "react";

import Box from "@mui/material/Box/Box";
import List from "@mui/material/List/List";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from "@mui/material/Divider/Divider";
import { RadioButtonCheckedIcon, RadioButtonUncheckedIcon, RadioButtonIndeterminateIcon } from "./Icons";
import CloseIcon from '@mui/icons-material/Close';

import { fetchTaskAssignedList, fetchTaskAssignment, StoreTaskListType } from '../api/taskRequest';

import Checkbox from "@mui/material/Checkbox/Checkbox";
import ListItem from "@mui/material/ListItem/ListItem";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import IconButton from "@mui/material/IconButton/IconButton";
import theme from "../theme";
import Button from "@mui/material/Button/Button";

import { setSendStore } from "../reducer/sendStore";
import { useDispatch, useSelector } from "react-redux";
import { setFuncBarStatus } from "../reducer/funcBarStatus";
import { setEventSequence } from "../reducer/eventStatus";

const style = {
  display: "flex",
  flexDirection: "column",
  bgcolor: "#FFF",
  boxShadow: 24,
  borderRadius: "20px 20px 0 0",
  p: 2,
  width: "100dvw",
  height: "calc(100dvh - 100px)",
  transition: "height 0.3s ease",
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
};

interface StoreListProps {
  taskList: String[];
}

const StoreAction = forwardRef<HTMLDivElement, StoreListProps>(({ taskList }, ref) => {
  const dispatch = useDispatch();
  const eventStatus = useSelector((state: any) => state.event);
  
  const [data, setData] = useState<StoreTaskListType>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchTaskAssignedList(taskList as string[]);
        setData(result);
      } catch (error) {
        console.error("Error fetching task assigned list:", error);
        setData([]);
      }
    };

    fetchData();
  }, [taskList]);

  const storeList = data || [];

  const handleClose = () => { 
    dispatch(setSendStore(false)); 
    dispatch(setFuncBarStatus(true));
    dispatch(setEventSequence(eventStatus.sequence + 1));
  };
  const handleConfirm = () => { 
    const fetchData = async () => {
      try {
        await fetchTaskAssignment(taskList as string[], storeList);
      } catch (error) {
        console.error("Error fetching task assigned list:", error);
        setData([]);
      }
    };

    fetchData();
    dispatch(setSendStore(false)); 
    dispatch(setFuncBarStatus(true));
    dispatch(setEventSequence(eventStatus.sequence + 1));
  };

  return (
    <Box ref={ref} sx={style} alignContent={"center"} alignItems={"flex-start"}>
      <Stack direction={'row'} sx={{ width: '100%', justifyContent: 'space-between', mb: 2 }} >
        <Typography variant="h2" sx={{ p: 1, fontSize: '16px', fontWeight: 700, color: '#424242', flexShrink: 0 }}>점포 선택</Typography>
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon sx={{ strokeWidth: 1, stroke: theme.palette.info.main }}/>
        </IconButton>
      </Stack>
        <List sx={{ 
          width: "100%",
          flex: 1,
          overflow: 'auto',
          p: 1,
        }}>
          <ListItem sx={{ px: '0px', py: '0px', flex: '1 0 100%' }}>
              <Checkbox icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                sx={{ '&:hover': { bgcolor: 'transparent' }, ml: '7px' }}
                onChange={(event) => {
                  const newChecked = event.target.checked;
                  const updatedList = data.map(storeItem => ({
                    ...storeItem, checked: newChecked, indeterminate: false
                  }));
                  setData(updatedList);
                }}
              />
              <ListItemButton dense
                key={"stora-all"}
                sx={{ minHeight: "42px" }}
              >
                <ListItemText primary={"전체 선택"} sx={{ pl: 1, fontWeight: 700, color: '#007CFF' }} />
              </ListItemButton>
            </ListItem>
            <Divider
              variant="fullWidth" component="li"
              sx={{ flexGrow: 1, borderColor: "#E0E0E0", borderBottomWidth: "1px", borderTopWidth: "0px", }}
            />
          {storeList.map((item, index) => (
            <Fragment key={item.bizplCd}>
              <ListItem sx={{ px: '0px', py: '0px', flex: '1 0 100%' }}>
                <Checkbox icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  indeterminateIcon={<RadioButtonIndeterminateIcon/>}
                  sx={{ '&:hover': { bgcolor: 'transparent' }, ml: '7px' }}
                  checked={item.checked}
                  indeterminate={item.indeterminate}
                  onChange={(event) => {
                    const newChecked = event.target.checked;
                    const updatedList = data.map(storeItem => ({
                      ...storeItem,
                      checked: storeItem.bizplCd === item.bizplCd ? newChecked : storeItem.checked,
                      indeterminate: storeItem.bizplCd === item.bizplCd ? false : storeItem.indeterminate
                    }));
                    setData(updatedList);
                  }}
                />
                <ListItemButton dense
                  key={item.bizplCd}
                  sx={{ minHeight: "42px" }}
                >
                  <ListItemText primary={item.bizplNm} sx={{ pl: 1 }} />
                </ListItemButton>
              </ListItem>
              {index < storeList.length - 1 && (
                <Divider
                  variant="fullWidth"
                  component="li"
                  sx={{
                    flexGrow: 1,
                    borderColor: "#E0E0E0",
                    borderBottomWidth: "1px",
                    borderTopWidth: "0px",
                  }}
                />
              )}
            </Fragment>
          ))}
        </List>
        <Stack direction={'row'} sx={{ width: '100%', justifyContent: 'flex-end', mt: 2, mb: 1 }} >
          <Button  onClick={handleClose} sx={{ fontSize: '16px', fontWeight: 700, color: '#424242', width: '70px' }}>취소</Button>
          <Button  onClick={handleConfirm} sx={{ fontSize: '16px', fontWeight: 700, color: '#424242', width: '70px' }}>확인</Button>
        </Stack>
    </Box>
  );
});

export default StoreAction;
