import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TabState {
  selectedIndex: number;
  deployTask?: boolean;
}

const initialState: TabState = {
  selectedIndex: 0,
  deployTask: false,
};

const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    setTabIndex: (state, action: PayloadAction<number>) => {
      state.selectedIndex = action.payload;
    },
    setDeployTask: (state, action: PayloadAction<boolean>) => {
      state.deployTask = action.payload;
    },
  },
});

export const { setTabIndex } = tabSlice.actions;
export default tabSlice.reducer;