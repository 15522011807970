import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FuncBarStatus {
  isChecked: boolean;
}

const initialState: FuncBarStatus = {
    isChecked: false,
};

const funcBarSlice = createSlice({
  name: "funcBar",
  initialState,
  reducers: {
    setFuncBarStatus: (state, action: PayloadAction<boolean>) => {
      state.isChecked = action.payload;
    },
  },
});

export const { setFuncBarStatus } = funcBarSlice.actions;
export default funcBarSlice.reducer;