import { Configuration } from "@azure/msal-browser";

const AppClientId =
	process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID;
const AzureTenantId =
	process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_TENANT_ID;

const productRedirectUrl = 
    process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/redirct'
    : `https://opt.gsrdt.com/redirct`;

export const msalConfig: Configuration = {
    auth: {
        clientId: AppClientId || '',
        authority: `https://login.microsoftonline.com/${AzureTenantId}`,
        redirectUri: productRedirectUrl,
        // postLogoutRedirectUri: "/"
    },
    cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: true,
	},
};

export const loginRequest = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};