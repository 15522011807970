import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EventState {
  open: boolean;
  mode: string;
  sequence: number;
  taskId: string | null;
  taskType: string | null;
  targetDate: string | null;
  task: string | null;
}

const initialState: EventState = {
  open: false,
  mode: 'add',
  sequence: 0,
  taskId: null,
  taskType: null,
  targetDate: null,
  task: null,
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEventOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setEventMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setEventSequence: (state, action: PayloadAction<number>) => {
      state.sequence = action.payload;
    },
    setEventTaskId: (state, action: PayloadAction<string | null>) => {
      state.taskId = action.payload;
    },
    setEventTaskType: (state, action: PayloadAction<string | null>) => {
      state.taskType = action.payload;
    },  
    setEventTargetDate: (state, action: PayloadAction<string | null>) => {
      state.targetDate = action.payload;
    },
    setEventTask: (state, action: PayloadAction<string | null>) => {
      state.task = action.payload;
    },
  },
});

export const { setEventOpen, setEventMode, setEventSequence, setEventTaskId, setEventTaskType, setEventTargetDate, setEventTask } = eventSlice.actions;
export default eventSlice.reducer;