import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const InputBox = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    backgroundColor: '#F2F2F2',
    borderRadius: 10,
    '& .MuiInputBase-input': {
      borderRadius: 10,
      position: 'relative',
      backgroundColor: '#F2F2F2',
      borderColor: '#F2F2F2',
      fontSize: 14,
      width: '100%',
      padding: '15px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  export default InputBox;